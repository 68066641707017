import Rails from '@rails/ujs'
import { createConsumer } from '@rails/actioncable'
import jquery from 'jquery'

import AjaxSetup from './src/models/jquery_ajax_setup'
import Block from './src/models/block'
import Message from './src/models/message'

import 'bootstrap'

// @ts-ignore
window.$ = window.jQuery = jquery

Rails.start()
// @ts-ignore
window.App = { cable: createConsumer() }
AjaxSetup.setup()
// @ts-ignore
window.Rails = Rails
// @ts-ignore
window.Block = Block
// @ts-ignore
window.Block.bindEvent()
// @ts-ignore
window.Message = Message
// @ts-ignore
window.Message.bind()

// entryModalモーダルが開いたら最初のinput要素にカーソルをフォーカスする
document.addEventListener(
  'DOMContentLoaded',
  function () {
    if (document.getElementById('entryModal')) {
      const entryModal = document.getElementById('entryModal')
      entryModal.addEventListener('shown.bs.modal', function () {
        entryModal.querySelector('input').focus()
      })
    }
  },
  false
)
