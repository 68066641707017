import UI from 'lockui'

export default class Block {
  static block (message: string = '処理中') : void {
    if (Block.isBlocked()) {
      return
    }
    UI.lock({ text: message })
  }

  static updateMessage (message: string) : void {
    $('.lockui-container').text(message)
  }

  static unblock () : void {
    UI.unlock()
  }

  static isBlocked () : boolean {
    return document.querySelector('.lockui-parent') !== null
  }

  static bindEvent (selector: string = '.js-block-button') {
    $(function () {
      $(selector).on('click', function () {
        Block.block()
      })
    })
  }
}
