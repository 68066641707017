import Block from './block'
import Message from './message'
import ErrorPage from './error_page'

export default class AjaxSetup {
  static setup () {
    $(() => {
      $.ajaxSetup({
        headers: {
          // @ts-ignore
          'X-CSRF-Token': Rails.csrfToken()
        },
        beforeSend: function () {
          Block.block()
          Message.hide()
        },
        error: function (jqXHR, _textStatus, _errorThrown) {
          // fail
          if (jqXHR.status === 409) {
            ErrorPage.conflictError()
            return
          }

          if (jqXHR.responseJSON && jqXHR.responseJSON.error_messages && jqXHR.responseJSON.error_messages.length > 0) {
            Message.alert(jqXHR.responseJSON.error_messages)
          } else {
            Message.alert('登録に失敗しました')
          }
        },
        complete: function (_jqXHR, _textStatus) {
          // always
          Block.unblock()
        }
      })
    })
  }
}
